/*---
Custom Color add to theme
---*/
.enter-raffles-wrapper .view-all, .active-raffle .view-all {
  font-weight: 500;
  font-size: 16px;
  color: #1a46e5; }
  @media (max-width: 767.98px) {
    .enter-raffles-wrapper .view-all, .active-raffle .view-all {
      font-size: 12px;
      font-weight: bold; } }

.enter-raffles-wrapper .swiper-slide, .active-raffle .swiper-slide {
  width: auto; }

.premium .enter-raffles-wrapper {
  margin-top: 72px; }

.enter-raffles-wrapper {
  margin-top: 112px; }
  .enter-raffles-wrapper .bg-wallet {
    padding: 15px 0px;
    background-image: linear-gradient(to bottom, #3e68ff, #1a46e5);
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px; }
    .enter-raffles-wrapper .bg-wallet.nickx {
      background-image: linear-gradient(to bottom, #fc7323, #fc7323); }
    .enter-raffles-wrapper .bg-wallet img {
      max-width: 42px; }

.swiper-raffle {
  min-height: 380px; }

.enter-raffles-wrapper {
  background: transparent; }
  @media (max-width: 1199.98px) {
    .enter-raffles-wrapper h5 {
      font-size: 14px; } }
  .enter-raffles-wrapper .back-arrow img {
    transform: rotate(180deg); }

.active-raffle {
  background: transparent; }
  @media (max-width: 1199.98px) {
    .active-raffle h5 {
      font-size: 14px; } }
