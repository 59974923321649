/*---
Custom Color add to theme
---*/
.modalV1.modal .modal-footer .prev-btn:focus, .modalV1.modal .modal-footer .prev-btn:active,
.modalV1.modal .modal-footer .next-btn:focus,
.modalV1.modal .modal-footer .next-btn:active {
  outline: none; }

.modalV1.modal .modal-footer .prev-btn {
  font-size: 16px;
  color: #7b8997;
  cursor: pointer; }
  .modalV1.modal .modal-footer .prev-btn.swiper-button-disabled {
    display: none; }

.modalV1.modal .modal-footer .next-btn {
  font-weight: bold;
  font-size: 16px;
  color: #1a46e5;
  cursor: pointer; }

.modalV1.modal .modal-footer .onboarding-pagination span {
  margin-left: 4px;
  margin-right: 4px; }
  @media (max-width: 575.98px) {
    .modalV1.modal .modal-footer .onboarding-pagination span {
      margin-left: 3px;
      margin-right: 3px; } }
  .modalV1.modal .modal-footer .onboarding-pagination span.swiper-pagination-bullet {
    background: #1b46e5; }

.modalV1.modal .popup-dismiss {
  position: absolute;
  right: 25px;
  cursor: pointer;
  top: 25px; }

.modalV1.modal .swiper-slide {
  height: 92%; }

.tutorial.swiper-slide {
  width: 100% !important; }
