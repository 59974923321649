/*---
Custom Color add to theme
---*/
.modalV2 .login-form .forgot-password {
  padding-left: 0px;
  font-weight: normal;
  font-size: 12px;
  color: #1a46e5; }

.modalV2 .login-form .btn-outline-secondary {
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  margin-top: -1px;
  letter-spacing: normal;
  text-align: center;
  color: white;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ff355d;
  border-radius: 100px;
  border: 2px solid #3e68ff;
  color: #3e68ff;
  text-shadow: none;
  font-weight: 500;
  font-size: 15px; }
  .modalV2 .login-form .btn-outline-secondary.btn-xs {
    padding: 15px 60px;
    font-size: 1rem;
    line-height: 16px;
    border-radius: 100px; }
  .modalV2 .login-form .btn-outline-secondary.btn-sm {
    padding: 8px 75px;
    font-size: 1rem;
    line-height: 36px;
    border-radius: 100px; }
  .modalV2 .login-form .btn-outline-secondary.btn-lg {
    padding: 10px 90px;
    font-size: 1rem;
    line-height: 36px;
    border-radius: 100px; }
  .modalV2 .login-form .btn-outline-secondary.btn-xl {
    padding: 10px 60px;
    font-size: 1rem;
    line-height: 36px;
    border-radius: 100px; }
  .modalV2 .login-form .btn-outline-secondary.btn-block {
    padding: auto auto;
    font-size: 1rem;
    line-height: 45px;
    border-radius: 100px; }
  .modalV2 .login-form .btn-outline-secondary.unlock {
    padding: auto auto;
    font-size: 0.75rem;
    line-height: 1.5;
    border-radius: 24px; }
  .modalV2 .login-form .btn-outline-secondary.social-login {
    padding: auto auto;
    font-size: 1rem;
    line-height: 25px;
    border-radius: 100px; }
    .modalV2 .login-form .btn-outline-secondary.social-login .social-icon.facebook img {
      width: 0.7rem; }
    .modalV2 .login-form .btn-outline-secondary.social-login .social-icon.google img {
      width: 1.3rem; }
  .modalV2 .login-form .btn-outline-secondary:hover {
    background: #e8edfc; }

.modalV2 .login-form .without-lable .field-icon.eye-open {
  background-image: url("../../../assets/images/svg/eye-default.svg"); }

.modalV2 .login-form .without-lable .field-icon.eye-close {
  background-image: url("../../../assets/images/svg/eye-close.svg"); }

.modalV2 .login-form .without-lable .field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -25px;
  position: relative;
  z-index: 99999999999;
  width: 25px;
  height: 25px; }
