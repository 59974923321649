/*---
Custom Color add to theme
---*/
.mt-6 {
  margin-top: 5rem; }

.premium .banner.clam-prize {
  position: relative; }
  .premium .banner.clam-prize:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-size: contain;
    background-image: url("../../assets/images/premium-shap.png");
    background-repeat: no-repeat;
    background-position: top right; }

.banner.clam-prize {
  background-image: url("../../assets/images/adv/banner-1.jpeg");
  background-repeat: no-repeat;
  width: 100%;
  height: 450px;
  background-size: cover;
  position: relative; }
  @media (max-width: 991.98px) {
    .banner.clam-prize {
      padding: 16vw;
      height: 100%;
      background-size: 100% 100%; } }

.clam-prize-wrapper {
  position: relative;
  padding: 25px 0px;
  background: #ffffff;
  box-shadow: 0 2px 10px -3px rgba(69, 75, 84, 0.24); }
  .clam-prize-wrapper p {
    margin-bottom: 0px; }
  .clam-prize-wrapper h1 {
    z-index: 2;
    position: relative; }
  .clam-prize-wrapper .clam-prize-zaggles {
    position: absolute;
    left: 0;
    right: 0;
    width: 1000px;
    top: -40px;
    margin: 0 auto; }
  @media (max-width: 1199.98px) {
    .clam-prize-wrapper .clam-prize-zaggles {
      max-width: 100%;
      width: 100vh;
      top: 0px; } }
  @media (max-width: 575.98px) {
    .clam-prize-wrapper h1 {
      font-size: 20px; }
    .clam-prize-wrapper .clam-prize-zaggles {
      width: 100%;
      top: 0px; } }

.receive-bitcoin {
  background-image: linear-gradient(to top, #ffffff, #f8faff); }
  .receive-bitcoin .receive-bitcoin-img {
    width: 100%;
    max-width: 340px;
    height: 300px;
    background: #cccccc;
    border-radius: 16px;
    margin: 0 auto;
    overflow: hidden;
    box-shadow: 0 9px 40px -8px rgba(0, 0, 0, 0.2); }
    .receive-bitcoin .receive-bitcoin-img img {
      object-fit: cover;
      width: 100%;
      height: 100%; }
  @media (max-width: 575.98px) {
    .receive-bitcoin h2 {
      font-size: 14px; }
    .receive-bitcoin h1 {
      font-size: 21px; } }

.clam-prize-accordian .tournament-accordian .card {
  background: transparent; }
  .clam-prize-accordian .tournament-accordian .card.bb-1 {
    box-shadow: inset 0 -1px 0 0 #e0e4e8; }
  .clam-prize-accordian .tournament-accordian .card .card-header {
    background: transparent;
    padding: 25px 0px;
    cursor: pointer; }
    .clam-prize-accordian .tournament-accordian .card .card-header .arrow {
      transition: all 0.5s ease-in-out; }
  .clam-prize-accordian .tournament-accordian .card .card-body {
    padding: 0px 0px 25px 0px; }
    .clam-prize-accordian .tournament-accordian .card .card-body img {
      max-width: 65px; }

@media (max-width: 991.98px) {
  .clam-prize-accordian p br {
    display: none; } }

.prize-form-wrapper {
  margin-top: 7rem; }
  .prize-form-wrapper .get-prize-form [class^="col-"] {
    margin-top: 4rem; }
  @media (max-width: 575.98px) {
    .prize-form-wrapper {
      margin-top: 4rem; }
      .prize-form-wrapper .get-prize-form [class^="col-"] {
        margin-top: 3rem; }
      .prize-form-wrapper .get-prize-form .button-wrapper [class^="col-"] {
        margin-top: 2rem; } }

.dropdown {
  position: relative; }
  .dropdown .selection-heading {
    padding: 20px 15px 15px 15px;
    margin-bottom: 0px;
    font-size: 15px;
    font-weight: 500;
    box-shadow: inset 0 -1px 0 0 #e0e4e8; }
  .dropdown .down-arrow-dropdown {
    position: absolute;
    right: 15px;
    top: 5px;
    z-index: 3;
    transition: all 0.5s ease-in-out; }
    .dropdown .down-arrow-dropdown.openArrow {
      transform: rotate(180deg); }
  .dropdown--open .dropdown__list {
    display: block; }

.dropdown__list {
  position: absolute;
  z-index: 2;
  display: none;
  top: -15px;
  left: 0;
  width: 100%;
  overflow: hidden;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1); }
  .dropdown__list ul {
    max-height: 200px;
    overflow: auto;
    margin-bottom: 0px; }
  .dropdown__list li {
    padding: 10px 15px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer; }
    .dropdown__list li:hover {
      background-color: rgba(224, 228, 232, 0.3); }
