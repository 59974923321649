/*---
Custom Color add to theme
---*/
.banner.dashboard .book-yoour-seat {
  background-image: linear-gradient(to bottom, rgba(62, 104, 255, 0.9), rgba(26, 70, 229, 0.9));
  padding: 12px 0px; }
  .banner.dashboard .book-yoour-seat.nickx {
    background-image: linear-gradient(to bottom, #ff9400, #ff6d00); }
  .banner.dashboard .book-yoour-seat h4 {
    margin-bottom: 0px;
    font-weight: 500;
    white-space: nowrap; }
    @media (max-width: 767.98px) {
      .banner.dashboard .book-yoour-seat h4 {
        font-size: 13px; } }
    @media (max-width: 575.98px) {
      .banner.dashboard .book-yoour-seat h4 {
        font-size: 11px; } }
  @media (max-width: 767.98px) {
    .banner.dashboard .book-yoour-seat .btn-pink {
      font-size: 10px;
      line-height: 25px; } }
  @media (max-width: 575.98px) {
    .banner.dashboard .book-yoour-seat .btn-pink {
      font-size: 9px;
      line-height: 21px; } }

@media (max-width: 991.98px) {
  .banner.dashboard {
    height: 100%;
    background-size: 100% 100%; } }

.book-yoour-seat.responsive {
  background-image: linear-gradient(to bottom, rgba(62, 104, 255, 0.9), rgba(26, 70, 229, 0.9));
  padding: 8px 0px; }
  .book-yoour-seat.responsive h4 {
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 12px;
    white-space: nowrap;
    padding: 8px 0px; }
  .book-yoour-seat.responsive .btn-pink {
    font-size: 14px;
    line-height: 25px; }

.banner-advertising img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  object-fit: contain; }

@media (max-width: 991.98px) {
  .banner-advertising {
    width: 100%;
    height: 150px; } }

@media (max-width: 767.98px) {
  .banner-advertising {
    width: 100%;
    height: 100%; } }

.banner-advertising.xlg {
  width: 1192px;
  height: 334px;
  margin-top: 70px;
  margin-bottom: 70px;
  height: 100%;
  margin-top: 40px;
  margin-bottom: 0px; }

.banner-advertising.lg {
  width: 970px;
  height: 250px;
  margin-top: 70px;
  margin-bottom: 70px; }

.banner-advertising.sm {
  width: 728px;
  height: 90px; }

.banner-advertising.web-square {
  width: 300px;
  height: 250px; }

.swiper-pagination {
  position: relative !important;
  width: 100%;
  text-align: center; }

.swiper-pagination-bullet {
  margin-left: 5px; }

.banner-wrapper .swiper-wrapper {
  width: 100% !important; }
