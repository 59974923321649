@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,700");
.maintenance {
  h1,
  h2,
  h4,
  h4.bold,
  h4.semi-bold,
  h5,
  h5.bold,
  h6,
  h6.bold,
  .display-1 {
    font-family: inherit;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }

  html,
  body {
    font-family: "Montserrat", sans-serif;
    font-weight: normal;
  }

  .comingsoon img {
    width: 260px;
    margin-bottom: 3rem;
  }

  .comingsoon .h-100vh {
    height: 100vh;
  }

  .comingsoon .lsp-18 {
    letter-spacing: 18px;
  }

  @media (max-width: 767.98px) {
    .comingsoon .lsp-18 {
      letter-spacing: 0px;
    }
  }

  .arcadexmobi-section {
    background: radial-gradient(
      circle at 115% 100%,
      #3e68ff,
      #1a46e5 40%,
      #0024a8
    );
    position: relative;
    overflow: hidden;
  }

  .arcadexmobi-section::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    background-image: url("../assets/images/sec-3bg.png");
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: cover;
    width: 100%;
    height: 100%;
  }

  .display-1 {
    font-size: 40px;
    font-weight: bold;
    line-height: 1.2;
    font-size: 64px;
    line-height: 1.13;
  }

  @media (max-width: 767.98px) {
    .display-1 {
      font-size: 32px;
    }
  }

  @media (max-width: 991.98px) {
    .display-1 {
      font-size: 56px;
    }
  }

  @media (max-width: 767.98px) {
    .display-1 {
      font-size: 32px;
    }
  }

  .region {
    position: absolute;
    top: 5px;
    left: 6px;
    z-index: 9999;
    font-size: 10px;
    font-weight: normal;
    color: #fff;
  }
}
