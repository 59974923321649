/*---
Custom Color add to theme
---*/
#rootwizard .backend-pill.nav-pills {
  box-shadow: none; }
  #rootwizard .backend-pill.nav-pills .nav-item {
    pointer-events: none;
    z-index: 2;
    position: relative;
    color: #fff; }
    #rootwizard .backend-pill.nav-pills .nav-item p {
      color: white;
      font-weight: 500; }
    #rootwizard .backend-pill.nav-pills .nav-item:not(:last-child):after {
      content: "";
      z-index: -1;
      position: absolute;
      right: -20px;
      top: -20px;
      bottom: 0;
      background-image: url("../../assets/images/dot-arrow.png");
      width: 100%;
      background-repeat: no-repeat;
      background-position: center right;
      opacity: 0.5;
      transition: 1s;
      left: 0; }
    #rootwizard .backend-pill.nav-pills .nav-item.transparentDiv {
      pointer-events: visible;
      z-index: 2; }
      #rootwizard .backend-pill.nav-pills .nav-item.transparentDiv p {
        color: white; }
      #rootwizard .backend-pill.nav-pills .nav-item.transparentDiv:after {
        content: "";
        opacity: 1;
        left: 20px;
        z-index: -1; }
      #rootwizard .backend-pill.nav-pills .nav-item.transparentDiv .active {
        transform: scale(1.2);
        transition: all 0.5s ease-in-out; }
    #rootwizard .backend-pill.nav-pills .nav-item .nav-link {
      background-color: transparent; }
      #rootwizard .backend-pill.nav-pills .nav-item .nav-link .circle {
        border: 2px solid white;
        border-radius: 50%;
        display: inline-block; }
        #rootwizard .backend-pill.nav-pills .nav-item .nav-link .circle .inner {
          background-color: white;
          border-radius: 50%;
          margin: 5px; }
          #rootwizard .backend-pill.nav-pills .nav-item .nav-link .circle .inner img {
            padding: 15px; }
            #rootwizard .backend-pill.nav-pills .nav-item .nav-link .circle .inner img.on {
              display: none; }
            #rootwizard .backend-pill.nav-pills .nav-item .nav-link .circle .inner img.off {
              display: flex; }
      #rootwizard .backend-pill.nav-pills .nav-item .nav-link.active .circle, #rootwizard .backend-pill.nav-pills .nav-item .nav-link.active-fixed .circle {
        border: 2px solid #fff; }
        #rootwizard .backend-pill.nav-pills .nav-item .nav-link.active .circle .inner, #rootwizard .backend-pill.nav-pills .nav-item .nav-link.active-fixed .circle .inner {
          background-color: #fff; }
          #rootwizard .backend-pill.nav-pills .nav-item .nav-link.active .circle .inner img.on, #rootwizard .backend-pill.nav-pills .nav-item .nav-link.active-fixed .circle .inner img.on {
            display: flex; }
          #rootwizard .backend-pill.nav-pills .nav-item .nav-link.active .circle .inner img.off, #rootwizard .backend-pill.nav-pills .nav-item .nav-link.active-fixed .circle .inner img.off {
            display: none; }
    #rootwizard .backend-pill.nav-pills .nav-item p {
      margin-bottom: 0px; }

#rootwizard .tab-content-wrapper {
  background-image: linear-gradient(to top, #ffffff, #f8faff);
  border-top: 1px solid rgba(224, 228, 232, 0.5); }
  #rootwizard .tab-content-wrapper .tab-1 .media img,
  #rootwizard .tab-content-wrapper .tab-2 .media img,
  #rootwizard .tab-content-wrapper .tab-3 .media img {
    width: 120px; }
  #rootwizard .tab-content-wrapper .tab-1 .section-info,
  #rootwizard .tab-content-wrapper .tab-2 .section-info,
  #rootwizard .tab-content-wrapper .tab-3 .section-info {
    padding-left: 10%; }
    @media (max-width: 991.98px) {
      #rootwizard .tab-content-wrapper .tab-1 .section-info,
      #rootwizard .tab-content-wrapper .tab-2 .section-info,
      #rootwizard .tab-content-wrapper .tab-3 .section-info {
        padding-left: 15px; } }
  #rootwizard .tab-content-wrapper .tab-1 .with-lable .create-tournament-wrapper .form-control {
    font-size: 36px;
    font-weight: bold;
    padding-bottom: 20px;
    height: 50px; }
    @media (max-width: 991.98px) {
      #rootwizard .tab-content-wrapper .tab-1 .with-lable .create-tournament-wrapper .form-control {
        font-size: 18px; } }
  #rootwizard .tab-content-wrapper .tab-1 .with-lable .create-tournament-wrapper .effect-16 ~ label {
    top: -20px;
    font-size: 36px;
    font-weight: bold;
    color: #a3b8cc; }
    @media (max-width: 991.98px) {
      #rootwizard .tab-content-wrapper .tab-1 .with-lable .create-tournament-wrapper .effect-16 ~ label {
        font-size: 18px;
        top: 5px; } }
  #rootwizard .tab-content-wrapper .tab-1 .with-lable .create-tournament-wrapper .effect-16:focus ~ label,
  #rootwizard .tab-content-wrapper .tab-1 .with-lable .create-tournament-wrapper .has-content.effect-16 ~ label {
    top: -36px;
    font-size: 16px;
    font-weight: 500;
    color: #3c4055; }
  #rootwizard .tab-content-wrapper .tab-3 .bg-rewards {
    background: #fff;
    padding: 30px;
    box-shadow: 0 10px 10px 5px rgba(167, 184, 245, 0.05);
    border: 1px solid #aabaca;
    overflow: hidden; }
  #rootwizard .tab-content-wrapper .tab-3 .form-info ul li.list-group-control:first-child {
    padding-left: 0px; }
  #rootwizard .tab-content-wrapper .tab-3 .form-info ul li .select-game-box {
    box-shadow: none; }
  #rootwizard .tab-content-wrapper .upload-image {
    cursor: pointer; }
    #rootwizard .tab-content-wrapper .upload-image .file {
      visibility: hidden;
      position: absolute; }

#rootwizard .add-new-rewards {
  min-height: 310px;
  border: 1px dashed #aabaca; }
  #rootwizard .add-new-rewards img {
    width: 50px; }

#rootwizard .tab-indication-wrapper {
  background-image: linear-gradient(to top, #f8fbfe, #fff);
  border-top: 1px solid #aabaca;
  position: fixed;
  bottom: 0px;
  left: 0px;
  box-shadow: 0 10px 8px 15px rgba(86, 119, 151, 0.08);
  padding: 10px;
  z-index: 5; }
  #rootwizard .tab-indication-wrapper .mw-100 {
    min-width: 100px; }

#rootwizard .table tbody tr td,
#rootwizard .table tbody tr th {
  white-space: nowrap; }

.form-info ul li.list-group-control {
  padding-left: 5px;
  padding-right: 5px; }
  @media (max-width: 767.98px) {
    .form-info ul li.list-group-control {
      padding-left: 0px;
      padding-right: 0px; } }

.form-info ul li .select-game-box {
  background: #fff;
  padding: 15px 10px;
  box-shadow: 0 10px 10px 5px rgba(167, 184, 245, 0.05);
  border: 1px solid #aabaca;
  height: 60px; }
  .form-info ul li .select-game-box label.custom-control {
    cursor: pointer;
    padding-left: 0px; }
    .form-info ul li .select-game-box label.custom-control.custom-radio {
      --color: #1a46e5; }
      .form-info ul li .select-game-box label.custom-control.custom-radio .custom-control-input {
        display: none; }
        .form-info ul li .select-game-box label.custom-control.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
          background-color: var(--color);
          border-color: var(--color);
          background-size: 100%; }
      .form-info ul li .select-game-box label.custom-control.custom-radio .custom-control-indicator {
        background-image: url("../../assets/images/checked-circle.png");
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        top: 4px;
        right: 0;
        width: 20px;
        height: 20px;
        border: 2px solid #a3b8cc;
        transition: 0.3s;
        background: transperent;
        background-size: 0%;
        padding: 3px;
        background-position: center;
        background-repeat: no-repeat; }
  .form-info ul li .select-game-box label .labelc-content img {
    width: 30px; }

.custom-control.fill-checkbox {
  --color: #1a46e5; }
  .custom-control.fill-checkbox .fill-control-input {
    display: none; }
    .custom-control.fill-checkbox .fill-control-input:checked ~ .fill-control-indicator {
      background-color: var(--color);
      border-color: var(--color);
      background-size: 80%; }
  .custom-control.fill-checkbox .fill-control-indicator {
    background-image: url("../../assets/images/checked.png");
    border-radius: 3px;
    display: inline-block;
    position: absolute;
    top: 4px;
    left: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #a3b8cc;
    transition: 0.3s;
    background: transperent;
    background-size: 0%;
    padding: 3px;
    background-position: center;
    background-repeat: no-repeat; }
    .custom-control.fill-checkbox .fill-control-indicator.backend {
      left: auto;
      right: 0; }
  .custom-control.fill-checkbox .fill-control-description {
    font-size: 12px;
    display: flex;
    align-items: center;
    line-height: 28px;
    padding-left: 10px; }
    .custom-control.fill-checkbox .fill-control-description a {
      color: #1a46e5; }

.zIndex-1 {
  z-index: 100; }

.create-raffle-btn {
  min-width: 200px; }
