/*---
Custom Color add to theme
---*/
.modalV2 .create-avatar .avatar {
  position: relative; }
  .modalV2 .create-avatar .avatar .avatar-wrapper {
    position: relative;
    width: 200px;
    height: 200px; }
    .modalV2 .create-avatar .avatar .avatar-wrapper img {
      max-width: 200px;
      max-height: 200px;
      border-radius: 50%; }
    .modalV2 .create-avatar .avatar .avatar-wrapper .upload-button {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.85);
      padding-top: 5px;
      padding-bottom: 35px;
      width: 200px;
      margin: 0 auto;
      border-bottom-left-radius: 100px;
      border-bottom-right-radius: 100px; }
      .modalV2 .create-avatar .avatar .avatar-wrapper .upload-button img {
        width: 30px;
        height: 30px;
        margin-bottom: 5px; }
      .modalV2 .create-avatar .avatar .avatar-wrapper .upload-button .choose-photo {
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #1a46e5; }
    .modalV2 .create-avatar .avatar .avatar-wrapper .remove-avatar {
      position: absolute;
      bottom: 20px;
      right: 10px;
      background: #fff;
      box-shadow: 0 3px 16px -7px rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      padding: 5px; }
  .modalV2 .create-avatar .avatar .file {
    visibility: hidden;
    position: absolute; }

.modalV2 .iphone-SE-top.mt {
  margin-top: 2rem !important; }
