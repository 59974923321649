.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__header {
  background-color: #3e68ff !important;
  color: #fff !important;
  .react-datepicker__current-month {
    color: #fff !important;
  }
  .react-datepicker__day-names div {
    color: #fff !important;
    font-weight: bold;
  }
}
.react-datepicker__navigation--next {
  border-left-color: #fff !important;
}

.react-datepicker__navigation--previous {
  border-right-color: #fff !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: #3e68ff !important;
  top:0;
}

.react-datepicker {
  -webkit-box-shadow: 10px 10px 40px -9px rgba(0, 0, 0, 0.38);
  -moz-box-shadow: 10px 10px 40px -9px rgba(0, 0, 0, 0.38);
  box-shadow: 10px 10px 40px -9px rgba(0, 0, 0, 0.38);
  border: none;
}

.react-datepicker__header {
  border: 1px solid #3e68ff;
  border-radius: 0.3rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.react-datepicker,
.react-datepicker__header {
  .react-datepicker__day--selected,
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--keyboard-selected:hover {
    background-color: #ed2a51;
    border-radius: 50%;
  }
}
