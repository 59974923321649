/*---
Custom Color add to theme
---*/
.banner.friend-profile {
  margin-top: 72px;
  background-repeat: no-repeat;
  width: 100%; }
  .banner.friend-profile .profile-info img {
    max-width: 170px; }
    .banner.friend-profile .profile-info img.svg {
      width: 28px; }
  .banner.friend-profile .mt-6 {
    margin-top: 5rem; }
  .banner.friend-profile .br-light {
    border-right: 1px solid #e9ecef; }
  .banner.friend-profile .curve-bg {
    position: absolute;
    top: 40px;
    width: 27vw;
    left: 0px;
    z-index: -1;
    fill: #1a46e5; }
    .banner.friend-profile .curve-bg::before {
      content: "";
      background-image: url("../../assets/images/friend-profile-bg-curve.png");
      background-repeat: no-repeat;
      background-size: 40%;
      position: absolute;
      top: 0px;
      background-position: bottom left;
      left: 0;
      width: 100%;
      height: 100%; }
    .banner.friend-profile .curve-bg.nickx {
      fill: #fc7323; }
      .banner.friend-profile .curve-bg.nickx::before {
        background-image: url("../../assets/images/friend-profile-bg-curve-nick.png"); }
    @media screen and (max-width: 1399px) and (min-width: 1000px) {
      .banner.friend-profile .curve-bg {
        top: 70px;
        width: 31vw;
        left: -150px; } }
    @media screen and (max-width: 1599px) and (min-width: 1400px) {
      .banner.friend-profile .curve-bg {
        top: 113px;
        width: 26vw;
        left: -50px; } }
    @media screen and (max-width: 1600px) and (min-width: 1500px) {
      .banner.friend-profile .curve-bg {
        top: 95px;
        width: 28vw;
        left: -50px; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .banner.friend-profile .curve-bg {
      left: -100px; } }
  @media (max-width: 991.98px) {
    .banner.friend-profile .profile-pic-160 {
      width: 80px;
      height: 80px; }
    .banner.friend-profile h6 {
      font-size: 14px; }
    .banner.friend-profile .padder {
      padding: 3rem 0rem; }
    .banner.friend-profile .br-light {
      border-right: 0px; }
    .banner.friend-profile .name {
      font-size: 38px; } }
  @media (max-width: 991.98px) and (max-width: 767.98px) {
    .banner.friend-profile .name {
      font-size: 22px; } }
  @media (max-width: 991.98px) {
    .banner.friend-profile .profile-info h6 {
      font-size: 12px; }
      .banner.friend-profile .profile-info h6.text-secondary {
        color: #1a46e5 !important; }
    .banner.friend-profile .profile-info h2 {
      font-size: 16px; }
    .banner.friend-profile .profile-info.mt-6 {
      margin-top: 3rem; } }
  @media (max-width: 767.98px) {
    .banner.friend-profile .curve-bg {
      width: 50%;
      max-width: 100%; }
    .banner.friend-profile .profile-pic-160 {
      width: 80px;
      height: 80px; }
    .banner.friend-profile .padder {
      padding: 3rem 0rem; }
    .banner.friend-profile .name {
      width: 300px;
      margin: 0 auto; }
    .banner.friend-profile .br-light {
      border-right: 0px; } }
  @media (max-width: 575.98px) {
    .banner.friend-profile .curve-bg {
      width: 50%;
      max-width: 200px; }
    .banner.friend-profile .profile-info h6 {
      font-size: 12px; }
      .banner.friend-profile .profile-info h6.text-secondary {
        color: #1a46e5 !important; }
    .banner.friend-profile .profile-info h2 {
      font-size: 16px; }
    .banner.friend-profile .profile-info.mt-6 {
      margin-top: 3rem; } }
