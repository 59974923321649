/*---
Custom Color add to theme
---*/
.modalV2 {
  overflow: hidden; }
  .modalV2.modal {
    padding-right: 0px !important; }
    @media (max-width: 767.98px) {
      .modalV2.modal .modal-dialog-centered {
        height: 100vh;
        margin: 0px; }
        .modalV2.modal .modal-dialog-centered .modal-content {
          border: 0px;
          border-radius: 0px; } }
    .modalV2.modal .modal-content {
      border-radius: 24px;
      border: 0px;
      overflow: hidden;
      background-color: transparent; }
      .modalV2.modal .modal-content .modal-body {
        padding: 3.5rem;
        background-color: #fff; }
    .modalV2.modal .popup-dismiss {
      position: absolute;
      right: 25px;
      cursor: pointer;
      top: 25px; }
      @media (max-width: 991.98px) {
        .modalV2.modal .popup-dismiss {
          top: 80px; } }
  .modalV2 .card-popup {
    position: relative;
    z-index: 100; }
    .modalV2 .card-popup .mt-nagative-4 {
      margin-top: -5px;
      background: #fff;
      padding: 5px 0px;
      font-size: 12px;
      font-weight: 500;
      left: calc(50% - 30px);
      width: 60px;
      text-align: center; }
    .modalV2 .card-popup .gutter-space {
      padding: 0px 15px; }
      .modalV2 .card-popup .gutter-space [class^="col"] {
        padding-right: 0px;
        padding-left: 0px; }
    .modalV2 .card-popup .custom-top {
      padding-top: 1.8rem; }
    .modalV2 .card-popup .creat-account {
      font-size: 16px; }
  .modalV2 .cross-background {
    position: absolute;
    background: radial-gradient(circle at 111% 58%, #3e68ff, #1a46e5 50%, #0024a8); }
    .modalV2 .cross-background:after {
      content: "";
      background-image: url("../../assets/images/login-shape-secondary.png");
      position: absolute;
      background-repeat: no-repeat; }
    .modalV2 .cross-background.mobile {
      margin-top: -190px;
      left: 0;
      -webkit-clip-path: polygon(0 50%, 100% 0, 100% 100%, 0% 100%);
      clip-path: polygon(0 50%, 100% 0, 100% 100%, 0% 100%);
      width: 100%;
      height: 100%; }
      .modalV2 .cross-background.mobile:after {
        background-position: top right;
        background-size: 100% 100%;
        top: 0;
        left: -20px;
        width: 80%;
        height: 100%; }
      .modalV2 .cross-background.mobile.adjust {
        max-height: 230px; }
    .modalV2 .cross-background.nickx {
      background: #fc7323; }
      .modalV2 .cross-background.nickx:after {
        background-image: url("../../assets/images/login-shape-secondary-nick.png"); }
  .modalV2 .crossbackground-web {
    width: 100%;
    height: 100%;
    clip-path: url(#myClipPath); }
  .modalV2 .cross-box {
    position: absolute;
    top: 375px;
    left: 0; }
    .modalV2 .cross-box.no-social-btn {
      top: 320px; }
    .modalV2 .cross-box.create-player {
      top: 290px; }
      .modalV2 .cross-box.create-player.with-error {
        top: 365px; }
    .modalV2 .cross-box.with-error {
      top: 425px; }
      .modalV2 .cross-box.with-error.no-social-btn {
        top: 330px; }
  .modalV2.register .cross-box {
    top: 430px; }
    .modalV2.register .cross-box.no-social-btn {
      top: 355px; }
    .modalV2.register .cross-box.with-error {
      top: 480px; }
      .modalV2.register .cross-box.with-error.no-social-btn {
        top: 405px; }
  .modalV2 .popup-responsive {
    padding-top: 72px; }
    .modalV2 .popup-responsive.position-fixed {
      top: 0px; }
      .modalV2 .popup-responsive.position-fixed .cross-background {
        background: #3e68ff;
        width: 100%;
        height: 100%;
        position: absolute;
        margin-top: -190px;
        left: 0;
        -webkit-clip-path: polygon(0 20%, 130% 0, 100% 100%, 0% 100%);
        -ms-clip-path: polygon(0 20%, 130% 0, 100% 100%, 0% 100%);
        -moz-clip-path: polygon(0 20%, 130% 0, 100% 100%, 0% 100%);
        clip-path: polygon(0 20%, 130% 0, 100% 100%, 0% 100%); }
        .modalV2 .popup-responsive.position-fixed .cross-background.nickx {
          background: #fc7323; }
      .modalV2 .popup-responsive.position-fixed .iphone-SE-creat-account.mt-4 {
        margin-top: 0.5rem !important; }
      .modalV2 .popup-responsive.position-fixed .privacy-policy .mt-2 {
        margin-top: 0px !important; }
  .modalV2 .iphone-SE-top.mt-5 {
    margin-top: 1.8rem !important; }
  .modalV2 small {
    font-size: 12px; }

.registerwithterms .modal-dialog {
  max-width: 700px !important; }

.modalV2.registerwithterms .cross-box.no-social-btn {
  top: 380px; }
