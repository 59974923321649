/*---
Custom Color add to theme
---*/
.premium .free-game-wrapper {
  margin-top: 72px; }

.no-premium .free-game-wrapper {
  margin-top: 112px; }

.free-game-wrapper {
  background-image: linear-gradient(to top, #ffffff, #f8faff); }
  @media (max-width: 1199.98px) {
    .free-game-wrapper h5 {
      font-size: 18px; } }
  .free-game-wrapper .back-arrow img {
    transform: rotate(180deg); }
  .free-game-wrapper .free-game-collumn .free-to-play-game {
    width: 267px; }
    @media (max-width: 767.98px) {
      .free-game-wrapper .free-game-collumn .free-to-play-game {
        width: 195px; } }
  .free-game-wrapper .ply-btn.free-game {
    top: 45% !important; }
