/*---
Custom Color add to theme
---*/
.Arcadex-play .bannerImg img {
  max-height: 300px; }

.Arcadex-play .modal-body {
  padding: 0px !important; }

.Arcadex-play .card-body .player-info .avatar {
  border-radius: 50%;
  box-shadow: 0 6px 26px -8px rgba(60, 64, 85, 0.3);
  border: 2px solid #fff;
  margin-top: -40px; }
  .Arcadex-play .card-body .player-info .avatar img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover; }

.Arcadex-play .card-body .gift-img img {
  max-width: 100px; }

.Arcadex-play .card-body .cancel-btn {
  font-size: 16px; }

.mobile-scren .bannerImg img {
  max-height: 144px;
  object-fit: cover;
  object-position: top; }

.mobile-scren .card-body {
  padding: 0px; }
  .mobile-scren .card-body .player-info {
    background: #fff;
    box-shadow: 0 2px 10px -3px rgba(69, 75, 84, 0.24);
    padding: 10px 15px; }
  .mobile-scren .card-body .gift-wrapper {
    padding: 0px 30px; }

@media (min-width: 576px) {
  .mobile-scren .bannerImg img {
    max-height: 270px; } }
