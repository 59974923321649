/*---
Custom Color add to theme
---*/
#gameObject {
  height: 100%; }

object {
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch; }

.full-screen-yes {
  display: block;
  border: none;
  height: 100% !important;
  width: 100% !important;
  position: fixed !important;
  left: 0px;
  top: 0px; }

.nickx-tmnt-totally-turtles {
  display: block;
  border: none;
  border-radius: 1px !important;
  height: 100% !important;
  width: 100% !important;
  position: fixed !important;
  left: 0px;
  top: 0px;
  background-color: gray !important; }

.fullScreenText {
  margin-top: 15px;
  font-weight: bold;
  color: #ff4294; }

.fullScreenIcon {
  width: 15px;
  height: 15px; }

.gameExitButton {
  position: absolute;
  bottom: 0;
  right: 0;
  width: auto;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.9);
  color: #fff;
  padding: 10px 20px; }
  .gameExitButton img {
    width: 14px;
    margin-right: 7px; }
