/*---
Custom Color add to theme
---*/
.subscribedProcess {
  color: #ff9800; }

.subscribedSuccess {
  color: #00b400; }

.subscribedError {
  color: red; }

.about-arcadex-section {
  background: linear-gradient(to bottom, white, #f8faff); }

.home-banner-wrapper {
  position: relative; }
  .home-banner-wrapper .content-wrapper {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 0;
    height: calc(100%); }
    .home-banner-wrapper .content-wrapper .content {
      position: relative;
      bottom: 18vw; }
      @media screen and (max-width: 1300px) and (min-width: 1200px) {
        .home-banner-wrapper .content-wrapper .content {
          left: 5vw;
          bottom: 16vw; } }
  @media (max-width: 1199.98px) {
    .home-banner-wrapper .content {
      left: 5vw;
      bottom: 16vw; } }

.home-banner-responsive {
  background: #375bde;
  background-size: 100% auto;
  background-repeat: no-repeat;
  min-height: 850px;
  height: 100%;
  width: 100%;
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 90%);
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 90%); }
  .home-banner-responsive .responsive-banner-content {
    position: relative;
    bottom: 5rem;
    padding-left: 1rem;
    padding-right: 1rem; }
    .home-banner-responsive .responsive-banner-content .display-2 span {
      font-weight: bold; }
  @media only screen and (width: 414px) and (height: 736px) {
    .home-banner-responsive {
      min-height: 900px; }
      .home-banner-responsive .responsive-banner-content {
        bottom: 6rem; } }
  .home-banner-responsive.default-sectop {
    margin-top: 72px; }
  .home-banner-responsive .empty-gutter [class^="col-"] {
    padding-left: 0px;
    padding-right: 0px; }
  .home-banner-responsive.nickx {
    background: #fc7323;
    background-size: 100% auto;
    background-repeat: no-repeat; }
  @media (min-width: 576px) {
    .home-banner-responsive {
      background-image: url("../../assets/images/home-ipadBg.jpg");
      min-height: 1000px; }
      .home-banner-responsive.nickx {
        background-image: url("../../assets/images/home-ipadBg-2-nick.jpg"); } }
  @media (max-width: 575.98px) {
    .home-banner-responsive {
      background-image: url("../../assets/images/home-ipadBg.jpg");
      min-height: 590px; }
      .home-banner-responsive.nickx {
        background-image: url("../../assets/images/home-ipadBg-2-nick.jpg"); }
      .home-banner-responsive .responsive-banner-content p br {
        display: none; } }

.column-section .icon img {
  width: 192px;
  height: auto; }

.column-section .gutter-edge > [class^="col-"],
.column-section .gutter-edge > [class*=" col-"] {
  padding-right: 5px;
  padding-left: 5px; }

.column-section .negative-margin-top {
  margin-top: -6vw;
  padding: 5rem 0rem; }

@media (max-width: 991.98px) {
  .column-section .negative-margin-top {
    margin-top: -2rem; }
  .column-section p br {
    display: none; } }

@media only screen and (width: 1280px) and (height: 635px) {
  .column-section .negative-margin-top {
    margin-top: -4rem; } }

.nickxmobi-section {
  background-color: #fc7323;
  position: relative;
  overflow: hidden; }
  .nickxmobi-section::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    background-image: url("../../assets/images/sec-3bg-nick.png");
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: cover;
    width: 100%;
    height: 100%; }
  .nickxmobi-section .card.popular-arcadex .card-header {
    background-color: #fc833c; }
  .nickxmobi-section .backstyle {
    position: relative;
    z-index: 2; }
  .nickxmobi-section .arcadexmobi .arcadex-slide-wrapper .swiper-arcadexmobi {
    width: 97%;
    height: auto;
    overflow: hidden; }
  .nickxmobi-section .arcadexmobi .arcadex-content {
    padding-left: 6%; }
    @media (max-width: 991.98px) {
      .nickxmobi-section .arcadexmobi .arcadex-content {
        padding-left: 15px; } }
  @media (max-width: 991.98px) {
    .nickxmobi-section .arcadexmobi .w-md-60 {
      width: 60%; } }
  @media (max-width: 767.98px) {
    .nickxmobi-section .arcadexmobi {
      padding-left: 15px;
      padding-right: 15px; }
      .nickxmobi-section .arcadexmobi .w-md-60 {
        width: 100%; } }
  .nickxmobi-section .platform {
    margin-top: 8rem; }
    .nickxmobi-section .platform img {
      width: calc(100vw - 38vw);
      height: 100%; }
    .nickxmobi-section .platform .platform-content {
      margin-top: 8rem; }
    @media (max-width: 1199.98px) {
      .nickxmobi-section .platform .platform-content {
        margin-top: 3rem; }
        .nickxmobi-section .platform .platform-content br {
          display: none; } }
    @media (max-width: 991.98px) {
      .nickxmobi-section .platform {
        margin-top: 0rem; }
        .nickxmobi-section .platform img {
          width: 125vw; }
        .nickxmobi-section .platform .platform-content {
          margin-top: 1rem;
          padding-left: 15px;
          padding-right: 15px; }
        .nickxmobi-section .platform .w-md-60 {
          width: 60%; }
          .nickxmobi-section .platform .w-md-60 p br {
            display: none; } }
    @media (max-width: 767.98px) {
      .nickxmobi-section .platform {
        margin-top: 3rem; }
        .nickxmobi-section .platform img {
          width: 125vw; }
        .nickxmobi-section .platform .platform-content {
          margin-top: 1rem;
          padding-left: 15px;
          padding-right: 15px; }
        .nickxmobi-section .platform .w-md-60 {
          width: 100%; }
          .nickxmobi-section .platform .w-md-60 p br {
            display: none; } }
  .nickxmobi-section #token {
    margin-top: -5rem; }
    .nickxmobi-section #token img {
      height: auto;
      width: -webkit-fill-available; }
    .nickxmobi-section #token .token-conetnt {
      margin-top: 11rem; }
    @media (max-width: 991.98px) {
      .nickxmobi-section #token {
        margin-top: 8rem; }
        .nickxmobi-section #token .token-conetnt {
          margin-top: 1rem;
          padding-left: 15px;
          padding-right: 15px; }
          .nickxmobi-section #token .token-conetnt .w-md-60 {
            width: 60%; }
            .nickxmobi-section #token .token-conetnt .w-md-60 p br {
              display: none; } }
    @media (max-width: 767.98px) {
      .nickxmobi-section #token {
        margin-top: 5rem; }
        .nickxmobi-section #token .token-conetnt {
          margin-top: 1rem;
          padding-left: 15px;
          padding-right: 15px; }
          .nickxmobi-section #token .token-conetnt p br {
            display: none; }
          .nickxmobi-section #token .token-conetnt .w-md-60 {
            width: 100%; } }
  .nickxmobi-section .mb-26 {
    margin-bottom: 26px; }

.arcadexmobi-section {
  background: radial-gradient(circle at 115% 100%, #3e68ff, #1a46e5 40%, #0024a8);
  position: relative;
  overflow: hidden; }
  .arcadexmobi-section::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    background-image: url("../../assets/images/sec-3bg.png");
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: cover;
    width: 100%;
    height: 100%; }
  .arcadexmobi-section .backstyle {
    position: relative;
    z-index: 2; }
  .arcadexmobi-section .arcadexmobi .arcadex-slide-wrapper .swiper-arcadexmobi {
    width: 97%;
    height: auto;
    overflow: hidden; }
  .arcadexmobi-section .arcadexmobi .arcadex-content {
    padding-left: 6%; }
    @media (max-width: 991.98px) {
      .arcadexmobi-section .arcadexmobi .arcadex-content {
        padding-left: 15px; } }
  @media (max-width: 991.98px) {
    .arcadexmobi-section .arcadexmobi .w-md-60 {
      width: 60%; } }
  @media (max-width: 767.98px) {
    .arcadexmobi-section .arcadexmobi {
      padding-left: 15px;
      padding-right: 15px; }
      .arcadexmobi-section .arcadexmobi .w-md-60 {
        width: 100%; } }
  .arcadexmobi-section .platform {
    margin-top: 8rem; }
    .arcadexmobi-section .platform img {
      width: calc(100vw - 38vw);
      height: 100%; }
    .arcadexmobi-section .platform .platform-content {
      margin-top: 8rem; }
    @media (max-width: 1199.98px) {
      .arcadexmobi-section .platform .platform-content {
        margin-top: 3rem; }
        .arcadexmobi-section .platform .platform-content br {
          display: none; } }
    @media (max-width: 991.98px) {
      .arcadexmobi-section .platform {
        margin-top: 0rem; }
        .arcadexmobi-section .platform img {
          width: 125vw; }
        .arcadexmobi-section .platform .platform-content {
          margin-top: 1rem;
          padding-left: 15px;
          padding-right: 15px; }
        .arcadexmobi-section .platform .w-md-60 {
          width: 60%; }
          .arcadexmobi-section .platform .w-md-60 p br {
            display: none; } }
    @media (max-width: 767.98px) {
      .arcadexmobi-section .platform {
        margin-top: 3rem; }
        .arcadexmobi-section .platform img {
          width: 125vw; }
        .arcadexmobi-section .platform .platform-content {
          margin-top: 1rem;
          padding-left: 15px;
          padding-right: 15px; }
        .arcadexmobi-section .platform .w-md-60 {
          width: 100%; }
          .arcadexmobi-section .platform .w-md-60 p br {
            display: none; } }
  .arcadexmobi-section #token {
    margin-top: -5rem; }
    .arcadexmobi-section #token img {
      height: auto;
      width: -webkit-fill-available; }
    .arcadexmobi-section #token .token-conetnt {
      margin-top: 11rem; }
    @media (max-width: 991.98px) {
      .arcadexmobi-section #token {
        margin-top: 8rem; }
        .arcadexmobi-section #token .token-conetnt {
          margin-top: 1rem;
          padding-left: 15px;
          padding-right: 15px; }
          .arcadexmobi-section #token .token-conetnt .w-md-60 {
            width: 60%; }
            .arcadexmobi-section #token .token-conetnt .w-md-60 p br {
              display: none; } }
    @media (max-width: 767.98px) {
      .arcadexmobi-section #token {
        margin-top: 5rem; }
        .arcadexmobi-section #token .token-conetnt {
          margin-top: 1rem;
          padding-left: 15px;
          padding-right: 15px; }
          .arcadexmobi-section #token .token-conetnt p br {
            display: none; }
          .arcadexmobi-section #token .token-conetnt .w-md-60 {
            width: 100%; } }
  .arcadexmobi-section .mb-26 {
    margin-bottom: 26px; }

@media (max-width: 767.98px) {
  .populararcade-heading {
    font-size: 17px; } }

.about-arcadex-section p br {
  display: none; }

.about-arcadex-section .featured-logo ul li img {
  width: 100%; }
  @media (max-width: 767.98px) {
    .about-arcadex-section .featured-logo ul li img {
      width: 100px; } }

.news-letter {
  position: relative;
  overflow: hidden; }
  .news-letter::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: url("../../assets/images/newsletter-Shap@3x.png");
    background-repeat: no-repeat;
    background-position: 100% 0%;
    background-size: cover; }
    @media screen and (max-width: 1365px) and (min-width: 1200px) {
      .news-letter::before {
        width: 110%; } }
    @media screen and (max-width: 1700px) and (min-width: 1366px) {
      .news-letter::before {
        width: 105%; } }
    @media (max-width: 1199.98px) {
      .news-letter::before {
        background-position: 10% 0%; } }
    @media (max-width: 991.98px) {
      .news-letter::before {
        background-image: url("../../assets/images/svg/newsletter-Shap@3x-ipad.svg");
        background-repeat: no-repeat;
        background-position: top right;
        background-size: cover;
        height: 100%;
        top: -3px;
        width: 101%; } }
    @media (max-width: 767.98px) {
      .news-letter::before {
        background-image: url("../../assets/images/svg/newsletter-Shap@3x-Mobile.svg");
        background-repeat: no-repeat;
        background-position: top right;
        background-size: cover;
        height: 80%;
        top: -3px; } }
  .news-letter .subscrible-newsletter {
    padding: 10rem 15px; }
    .news-letter .subscrible-newsletter .form-group {
      flex-grow: 1; }
      .news-letter .subscrible-newsletter .form-group .form-control {
        width: 95%; }
      .news-letter .subscrible-newsletter .form-group .effect-1:focus ~ .focus-border {
        width: 95%;
        transition: 0.4s; }
    @media (max-width: 767.98px) {
      .news-letter .subscrible-newsletter {
        padding: 30vw 15px 5rem; }
        .news-letter .subscrible-newsletter .form-group .help-text {
          top: 140px; }
        .news-letter .subscrible-newsletter .btn-sm-block {
          display: block;
          width: 100%; }
        .news-letter .subscrible-newsletter h5 br {
          display: none; }
        .news-letter .subscrible-newsletter .subscribe-alignment-adjust {
          margin-top: 30px; } }
  .news-letter .subscribe-img img {
    max-width: 330px; }
  @media (max-width: 767.98px) {
    .news-letter .subscribe-img {
      max-width: 100%; } }
