/*---
Custom Color add to theme
---*/
.score-card .modal-body {
  padding: 0px !important; }

.score-card .card-body .player-info .avatar {
  border-radius: 50%;
  box-shadow: 0 6px 26px -8px rgba(60, 64, 85, 0.3);
  border: 2px solid #fff;
  margin-top: -40px; }
  .score-card .card-body .player-info .avatar img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover; }

.score-card .card-body .gift-img img {
  max-width: 200px; }

@media (max-width: 991.98px) {
  .score-card .card-body .gift-img img {
    max-width: 150px; } }

.score-card .card-body .gold-token img {
  max-width: 60px; }

.score-card .card-body .gold-token .earned-token {
  color: #7b8997; }

.score-card .card-body .gold-token.br-1 {
  border-right: 1px solid #e9ecef; }

.score-card .card-body .cancel-btn {
  font-size: 16px; }

.popup-dismiss {
  z-index: 999; }
