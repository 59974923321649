/*---
Custom Color add to theme
---*/
.modalV2 .create-player .input-group-wrapper {
  position: relative; }
  .modalV2 .create-player .input-group-wrapper .checked-playername {
    position: absolute;
    top: 0;
    right: 0; }

.modalV2 .create-player .goBackLink {
  text-decoration: underline;
  text-underline-position: under;
  font-size: 19px; }

.modalV2 .create-player .bottom-margin {
  margin-bottom: 80px; }

.modalV2 .iphone-SE-top.mt {
  margin-top: 2rem !important; }
