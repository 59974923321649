/*---
Custom Color add to theme
---*/
.banner.my-tournament {
  background-image: url("../../assets/images/adv/banner-1.jpeg");
  background-repeat: no-repeat;
  width: 100%;
  height: 500px;
  background-size: cover; }
  .banner.my-tournament.nickx {
    background-image: url("../../assets/images/adv/challenge-banner-nick.png"); }
  @media (max-width: 991.98px) {
    .banner.my-tournament {
      padding: 16vw;
      height: 100%;
      background-size: 100% 100%; } }

.tournamet-info-wrapper {
  padding: 25px 0px;
  background: #ffffff;
  box-shadow: 0 2px 10px -3px rgba(69, 75, 84, 0.24); }
  .tournamet-info-wrapper p {
    margin-bottom: 0px; }
  .tournamet-info-wrapper .responsive-adjust .media .timer {
    max-width: 32px;
    max-height: 32px; }
  .tournamet-info-wrapper .responsive-adjust .media .media-body span {
    font-weight: 500; }
  .tournamet-info-wrapper .responsive-adjust .ms-flex {
    flex: 1;
    -ms-flex: 1; }
  .tournamet-info-wrapper .counter-btn-wraper span {
    font-weight: normal;
    font-size: 12px; }
  .tournamet-info-wrapper .mt-negative-50 {
    margin-top: -50px; }
  .tournamet-info-wrapper .profile-avatar {
    max-width: 140px;
    width: 100%;
    box-shadow: 0 9px 20px -8px rgba(0, 0, 0, 0.3);
    border-radius: 50%; }
    @media (max-width: 991.98px) {
      .tournamet-info-wrapper .profile-avatar {
        max-width: 65px; } }
  @media (max-width: 991.98px) {
    .tournamet-info-wrapper .media {
      margin-top: 5px; }
    .tournamet-info-wrapper .countDown-timer {
      font-size: 11px; } }
  @media (max-width: 767.98px) {
    .tournamet-info-wrapper {
      padding: 12px 0px; }
      .tournamet-info-wrapper .title-small-responsive {
        font-size: 18px; }
      .tournamet-info-wrapper .countDown-timer {
        font-size: 11px; }
      .tournamet-info-wrapper .responsive-adjust [class^="col"] {
        padding-left: 0px; }
      .tournamet-info-wrapper .responsive-adjust a.btn.ml-2 {
        margin-left: 0px !important; }
      .tournamet-info-wrapper .responsive-adjust h6 {
        font-size: 16px; }
      .tournamet-info-wrapper .responsive-adjust .media {
        margin-top: 10px; }
        .tournamet-info-wrapper .responsive-adjust .media .timer {
          max-width: 24px;
          max-height: 24px; }
        .tournamet-info-wrapper .responsive-adjust .media .media-body h6 {
          font-size: 12px; } }
  .tournamet-info-wrapper.host-detail .social-media img {
    width: 7vw;
    max-width: 28px;
    height: 7vw;
    max-height: 28px; }
  @media (max-width: 991.98px) {
    .tournamet-info-wrapper.host-detail {
      padding: 20px 0px; }
      .tournamet-info-wrapper.host-detail .host-heading {
        margin-top: -15px;
        margin-bottom: 20px; }
        .tournamet-info-wrapper.host-detail .host-heading h1 {
          font-size: 21px; } }

.react-cntdwn-day {
  margin-left: 5px;
  font-weight: bold !important; }
  .react-cntdwn-day span {
    margin-left: 3px;
    font-weight: bold !important; }

.react-cntdwn-hour {
  margin-left: 5px;
  font-weight: bold !important; }
  .react-cntdwn-hour span {
    margin-left: 3px;
    font-weight: bold !important; }

.react-cntdwn-minute {
  margin-left: 5px;
  font-weight: bold !important; }
  .react-cntdwn-minute span {
    margin-left: 3px;
    font-weight: bold !important; }

.react-cntdwn-second {
  margin-left: 5px;
  font-weight: bold !important; }
  .react-cntdwn-second span {
    margin-left: 3px;
    font-weight: bold !important; }
